import { getRehydratableName } from "@dcl/ui";

/**
 * TODO: this file runs dynamic imports and immediately calls on the rehydrator function
 * this is not testable
 *
 * we should break this process down in order to also test dynamic imports
 *
 * this file should be dedicated to generating the dynamic `import()` and
 * getting the `rehydrator` func from the module object
 *
 * tests should be written to ensure that a `rehydrator` is returned
 *
 * we can offload wrapping the rehydrator into the `react-from-markup` spec in a file
 * supplying `renderBrowser`
 *
 * 1. `() => import -> rehydratorGetter`
 *  - test all the registered rehydrateFn are defined
 * 2. iterate to wrap to expose to `react-from-markup` and `renderBrowser`
 */

export default {
  [getRehydratableName("AnnotatedScreenshot")]: (...args) =>
    import("@dcl/ui/esm/fragments/AnnotatedScreenshot").then(Component =>
      Component.rehydrator(...args)
    ),
  [getRehydratableName("Carousel")]: (...args) =>
    import("@dcl/ui/esm/modules/Carousel").then(Component =>
      Component.rehydrator(...args)
    ),
  [getRehydratableName("DismissibleBanner")]: (...args) =>
    import("@dcl/ui/esm/fragments/DismissibleBanner").then(Component =>
      Component.rehydrator(...args)
    ),
  [getRehydratableName("Dropdown")]: (...args) =>
    import("@dcl/ui/esm/modules/Dropdown").then(Component =>
      Component.rehydrator(...args)
    ),
  [getRehydratableName("Expandable.Item")]: (...args) =>
    import("@dcl/ui/esm/modules/Expandable").then(Component =>
      Component.rehydrator(...args)
    ),
  [getRehydratableName("GlobalFooter")]: (...args) =>
    import("@dcl/ui/esm/fragments/GlobalFooter").then(Component =>
      Component.rehydrator(...args)
    ),
  [getRehydratableName("GlobalHeader")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/GlobalHeader")).rehydrator(...args),
  [getRehydratableName("GlobalHeader.BaseItem")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/GlobalHeader")).baseItemRehydrator(
      ...args
    ),
  [getRehydratableName("GlobalHeader.Cart")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/GlobalHeader")).cartRehydrator(
      ...args
    ),
  [getRehydratableName("GlobalHeader.Login")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/GlobalHeader")).loginRehydrator(
      ...args
    ),
  [getRehydratableName("Figure")]: (...args) =>
    import("@dcl/ui/esm/fragments/Figure").then(Component =>
      Component.rehydrator(...args)
    ),
  [getRehydratableName("FilteredList")]: (...args) =>
    import("@dcl/ui/esm/fragments/FilteredList").then(Component =>
      Component.rehydrator(...args)
    ),
  [getRehydratableName("FilteredList.ListItem")]: (...args) =>
    import("@dcl/ui/esm/fragments/FilteredList").then(Component =>
      Component.listItemRehydrator(...args)
    ),
  [getRehydratableName("Form")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/Form")).formRehydrator(...args),
  [getRehydratableName("Form.CampaignControl")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/Form")).campaignControlRehydrator(
      ...args
    ),
  [getRehydratableName("Form.PrivacyControl")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/Form")).privacyControlRehydrator(
      ...args
    ),
  [getRehydratableName("HorizontalOverflow")]: async (...args) =>
    (await import("@dcl/ui/esm/modules/HorizontalOverflow")).rehydrator(
      ...args
    ),
  [getRehydratableName("Longform.ChapterNavInner")]: async (...args) =>
    (await import("@dcl/ui/esm/modules/Longform")).chapterNavRehydrator(
      ...args
    ),
  [getRehydratableName("Longform.ChapterNav.Chapter")]: async (...args) =>
    (await import("@dcl/ui/esm/modules/Longform")).chapterNavChapterRehydrator(
      ...args
    ),
  [getRehydratableName("PageNavInner")]: (...args) =>
    import("@dcl/ui/esm/fragments/PageNav").then(Component =>
      Component.rehydrator(...args)
    ),
  [getRehydratableName("PageNav.Item")]: (...args) =>
    import("@dcl/ui/esm/fragments/PageNav").then(Component =>
      Component.itemRehydrator(...args)
    ),
  [getRehydratableName("SearchFilters.Filters")]: async (...args) =>
    (await import("@dcl/ui/esm/elements/SearchFilters")).filtersRehydrator(
      ...args
    ),
  [getRehydratableName("Popover")]: async (...args) =>
    (await import("@dcl/ui/esm/modules/Popover")).rehydrator(...args),
  [getRehydratableName("ProductDetail")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/ProductDetail")).rehydrator(...args),
  [getRehydratableName("SearchFilters.FilterSectionOverflow")]: async (
    ...args
  ) =>
    (
      await import("@dcl/ui/esm/elements/SearchFilters")
    ).filterSectionOverflowRehydrator(...args),
  [getRehydratableName("SearchFilters.Selected")]: async (...args) =>
    (await import("@dcl/ui/esm/elements/SearchFilters")).selectedReyhdrator(
      ...args
    ),
  [getRehydratableName("SearchInput")]: async (...args) =>
    (await import("@dcl/ui/esm/elements/SearchInput")).rehydrator(...args),

  [getRehydratableName("ShowMore")]: (...args) =>
    import("@dcl/ui/esm/fragments/ShowMore").then(Component =>
      Component.rehydrator(...args)
    ),

  [getRehydratableName("SiteFooter")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/SiteFooter")).rehydrator(...args),
  [getRehydratableName("SiteFooter.NavList")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/SiteFooter")).navListRehydrator(
      ...args
    ),
  [getRehydratableName("SiteHeader")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/SiteHeader")).rehydrator(...args),
  [getRehydratableName("SiteHeader.Menu")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/SiteHeader")).menuRehydrator(...args),
  [getRehydratableName("SiteHeaderFlyouts")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/SiteHeader")).flyoutsRehydrator(
      ...args
    ),
  [getRehydratableName("SiteHeader.Flyouts.TabPanel")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/SiteHeader")).flyoutsPanelRehydrator(
      ...args
    ),
  [getRehydratableName("TableWithFilters")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/TableWithFilters")).rehydrator(
      ...args
    ),
  [getRehydratableName("Tabs")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/Tabs")).rehydrator(...args),
  [getRehydratableName("Tabs.Panel")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/Tabs")).panelRehydrator(...args),
  [getRehydratableName("TabsAdvanced")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/TabsAdvanced")).rehydrator(...args),
  [getRehydratableName("Ticker")]: async (...args) =>
    (await import("@dcl/ui/esm/modules/Ticker")).rehydrator(...args),
  [getRehydratableName("Ticker.Item")]: async (...args) =>
    (await import("@dcl/ui/esm/modules/Ticker")).itemRehydrator(...args),
  [getRehydratableName("TwitterCardTimeline")]: (...args) =>
    import("@dcl/ui/esm/fragments/Card").then(Component =>
      Component.twitterCardTimelineRehydrator(...args)
    ),
  [getRehydratableName("VideoPlayerCore")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/VideoPlayer")).rehydrator(...args),

  [getRehydratableName("UserInfo")]: async (...args) =>
    (await import("./components/UserInfo")).rehydrator(...args),
  [getRehydratableName("CartInfo")]: async (...args) =>
    (await import("./components/CartInfo")).rehydrator(...args),

  [getRehydratableName("CoveoSearchBox")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/CoveoSearch/CoveoSearchBox")
    ).rehydrator(...args),
  [getRehydratableName("CoveoSearchResult")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/CoveoSearch/CoveoSearchResult")
    ).rehydrator(...args),
  [getRehydratableName("ProductSelection")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/ProductSelection")
    ).rehydrator(...args),
  [getRehydratableName("FrequentlyAskedQuestions")]: async (...args) =>
    (
      await import(
        "@dcl/ui/esm/fragments/PortalHelpCenter/FrequentlyAskedQuestions"
      )
    ).rehydrator(...args),
  [getRehydratableName("SearchHelpTopics")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/SearchHelpTopics")
    ).rehydrator(...args),
  [getRehydratableName("ContactUs")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/ContactUs")
    ).rehydrator(...args),
  [getRehydratableName("EmailArticle")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/EmailArticle")
    ).rehydrator(...args),
  [getRehydratableName("HelpCenterSpacer")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/HelpCenterSpacer")
    ).rehydrator(...args),
  [getRehydratableName("ClickablePhoneNumber")]: async (...args) =>
    (
      await import(
        "@dcl/ui/esm/fragments/PortalHelpCenter/ClickablePhoneNumber"
      )
    ).rehydrator(...args),
  [getRehydratableName("CopyLink")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/CopyLink")
    ).rehydrator(...args),
  [getRehydratableName("ContactUsHeader")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/ContactUsHeader")
    ).rehydrator(...args),
  [getRehydratableName("SupportTicket")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/SupportTicket")
    ).rehydrator(...args),
  [getRehydratableName("PageNotFound")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/PageNotFound")
    ).rehydrator(...args),
  [getRehydratableName("TalkWithUs")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/TalkWithUs")
    ).rehydrator(...args),
  [getRehydratableName("ChatWithUs")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/ChatWithUs")
    ).rehydrator(...args),
  [getRehydratableName("CommunityBanner")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/CommunityBanner")
    ).rehydrator(...args),
  [getRehydratableName("TermsAndConditions")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/TermsAndConditions")
    ).rehydrator(...args),
  [getRehydratableName("LanguageSelector")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/LanguageSelector")
    ).rehydrator(...args),
  [getRehydratableName("UltraTaxCS")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/UltraTaxCS")
    ).rehydrator(...args),
  [getRehydratableName("BrowseExperienceBanner")]: async (...args) =>
    (
      await import(
        "@dcl/ui/esm/fragments/PortalHelpCenter/BrowseExperienceBanner"
      )
    ).rehydrator(...args),
  [getRehydratableName("EmailUs")]: async (...args) =>
    (await import("@dcl/ui/esm/fragments/PortalHelpCenter/EmailUs")).rehydrator(
      ...args
    ),
  [getRehydratableName("TrainingExperienceVideos")]: async (...args) =>
    (
      await import(
        "@dcl/ui/esm/fragments/PortalHelpCenter/TrainingExperienceVideos"
      )
    ).rehydrator(...args),
  [getRehydratableName("CiamLogin")]: async (...args) =>
    (
      await import("@dcl/ui/esm/fragments/PortalHelpCenter/CiamLogin")
    ).rehydrator(...args),
  [getRehydratableName("FileDownloadExperience")]: async (...args) =>
    (
      await import(
        "@dcl/ui/esm/fragments/PortalHelpCenter/FileDownloadExperience"
      )
    ).rehydrator(...args)
};
